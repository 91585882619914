import React from 'react';
import Layout from '../layout';
import About from '../components/About';
import { SEO } from '../components/SEO';

const AboutPage: React.FC = () => {
  return (
    <Layout>
      <main>
        <SEO />
        <About />
      </main>
    </Layout>
  );
};

export default AboutPage;
