import React from 'react';

export const ExternalLink: React.FC<{
  url: string;
  linkText: string;
}> = ({ url, linkText }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {linkText}
    </a>
  );
};

export const LinkedInLink: React.FC<{ linkText?: string }> = ({
  linkText = `LinkedIn`,
}) => (
  <ExternalLink
    url="https://www.linkedin.com/in/zaklaughton/"
    linkText={linkText}
  />
);
export const TwitterLink: React.FC<{ linkText?: string }> = ({
  linkText = `Twitter`,
}) => (
  <ExternalLink
    url="https://www.twitter.com/zaklaughton/"
    linkText={linkText}
  />
);
export const GithubLink: React.FC<{ linkText?: string }> = ({
  linkText = `GitHub`,
}) => (
  <ExternalLink url="https://www.github.com/zaklaughton/" linkText={linkText} />
);
