import React from 'react';
import { Helmet } from 'react-helmet';
import urljoin from 'url-join';
import { useSiteMetadata } from '../utils/useSiteMetadata';

export const SEO: React.FC = () => {
  const siteMetadata = useSiteMetadata();

  return (
    <Helmet>
      <html lang="en" />
      <title>{siteMetadata.title}</title>
      {/* Fav Icons */}
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
      <link rel="alternate icon" href="/logos/coding.svg" />
      {/* Meta tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta name="description" content={siteMetadata.description || ``} />
      {/* Open Graph */}
      <meta property="og:url" content={siteMetadata.url || ``} />
      <meta
        property="og:image"
        content={'https://www.zaklaughton.dev/profile_photo.jpeg'}
      />
      <meta
        property="og:title"
        content={siteMetadata.title || ``}
        key="ogtitle"
      />
      <meta
        property="og:site_name"
        content={siteMetadata.title || ``}
        key="ogsitename"
      />
      <meta
        property="og:description"
        content={siteMetadata.description || ``}
        key="ogdesc"
      />
      <meta name="robots" content="index, follow" />
      {/* Twitter OG */}
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:creator"
        content={
          siteMetadata.owner?.usernames?.twitter
            ? siteMetadata.owner?.usernames?.twitter
            : ''
        }
      />
      <meta name="twitter:site" content={'@ZakLaughton'} />
      <meta name="twitter:creator" content={'@ZakLaughton'} />
      <meta name="twitter:title" content={siteMetadata.title || ``} />
      <meta
        name="twitter:description"
        content={siteMetadata.description || ``}
      />
      <meta
        name="twitter:image"
        content={'https://www.zaklaughton.dev/profile_photo.jpeg'}
      />
      <meta name="twitter:image:alt" content="Zak Laughton's profile photo" />
    </Helmet>
  );
};

/**
 * This SEO component came witha template, and the types/usage isn't clear. This
 * should eventually be migrated to the new SEO component above and removed
 */
// @ts-expect-error Property 'postNode' does not exist on type '{ children?: ReactNode; }'
const DeprecatedSEO: React.FC = ({ postNode, postPath, postSEO }) => {
  const siteMetadata = useSiteMetadata();

  const siteUrl = siteMetadata.siteUrl || '';

  let title;
  let description;
  let image;
  let postURL;
  if (postSEO) {
    const postMeta = postNode.frontmatter;
    ({ title } = postMeta);
    description = postMeta.description
      ? postMeta.description
      : postNode.excerpt;
    image = postMeta.cover;
    postURL = urljoin(siteUrl, postPath);
  } else {
    title = siteMetadata.title;
    description = siteMetadata.description;
    image = siteMetadata.logo;
  }

  image = urljoin(siteUrl, image);
  const blogURL = urljoin(siteUrl);
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: blogURL,
      name: title,
      alternateName: siteMetadata.titleAlt ? siteMetadata.titleAlt : '',
    },
  ];
  if (postSEO) {
    schemaOrgJSONLD.push(
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        // @ts-expect-error Object literal may only specify known properties, and 'itemListElement' does not exist in type
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': postURL,
              name: title,
              image,
            },
          },
        ],
      },
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url: blogURL,
        name: title,
        alternateName: siteMetadata.titleAlt ? siteMetadata.titleAlt : '',
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image,
        },
        description,
      }
    );
  }
  return (
    <Helmet>
      {/* General tags */}
      <meta name="description" content={description} />
      <meta name="image" content={image} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={postSEO ? postURL : blogURL} />
      {postSEO ? <meta property="og:type" content="article" /> : null}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta
        property="fb:app_id"
        content={siteMetadata.facebookAppID ? siteMetadata.facebookAppID : ''}
      />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:creator"
        content={
          siteMetadata.owner?.usernames?.twitter
            ? siteMetadata.owner?.usernames?.twitter
            : ''
        }
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content="Zak Laughton's profile photo" />
    </Helmet>
  );
};

export default DeprecatedSEO;
